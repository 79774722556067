import React from 'react'
import { Colors } from 'common-constants/colors'

export const RatingSvg = ({ color = Colors.link }: { color?: Colors }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      d="M11.999 5.595S10.139 3 6.498 3C3.46 3 1 5.516 1 8.623c0 3.202 1.655 5.838 3.937 7.891C6.997 18.37 9.487 19.875 12 21c2.513-1.125 5.002-2.631 7.064-4.486C21.347 14.461 23 11.825 23 8.624 23 5.515 20.538 3 17.5 3c-3.638 0-5.501 2.595-5.501 2.595z"
    />
  </svg>
)
