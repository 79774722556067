import React from 'react'

export const SearchDefaultSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="bowuruduka" x1="50%" x2="50%" y1="-38.527%" y2="100%">
        <stop offset="0%" stopColor="#B6E3FF" />
        <stop offset="100%" stopColor="#6AC5FF" />
      </linearGradient>
      <linearGradient id="3dy1gh6abb" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-549 -608) translate(549 608) translate(3 3)">
              <path
                stroke="url(#bowuruduka)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M12.75 11.917L18 16.335"
              />
              <circle
                cx="7.5"
                cy="7.5"
                r="5.75"
                stroke="url(#3dy1gh6abb)"
                strokeWidth="3.5"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
