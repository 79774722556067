import React from 'react'

export const SearchHoverSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="no9nt8d2ia"
        x1="50%"
        x2="50%"
        y1="-20.703%"
        y2="140.976%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
      <linearGradient
        id="rftxfyn0ob"
        x1="50%"
        x2="50%"
        y1="-20.703%"
        y2="140.976%"
      >
        <stop offset="0%" stopColor="#6CB8E8" />
        <stop offset="100%" stopColor="#0076C0" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-593 -608) translate(593 608) translate(3 3)">
            <path
              stroke="url(#no9nt8d2ia)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M12.75 11.917L18 16.335"
            />
            <circle
              cx="7.5"
              cy="7.5"
              r="5.75"
              stroke="url(#rftxfyn0ob)"
              strokeWidth="3.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
