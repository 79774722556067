import React from 'react'

export const RatingDefaultSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="fg6mmzpwva" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#6CC6FF" />
        <stop offset="100%" stopColor="#46AAE9" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#fg6mmzpwva)" transform="translate(-1012 -258)">
        <g>
          <path
            d="M11.938 5.539C12.942 4.013 15.127 3 17.155 3c3.14 0 5.82 2.487 5.82 5.475 0 0 1.025 5.963-9.96 12.187-.162.092-.593.333-1.015.338-.413.005-.816-.225-.968-.31C-.005 14.455 1.025 8.474 1.025 8.474 1.025 5.487 3.707 3 6.843 3c2.029 0 4.09 1.013 5.095 2.539h.402-.402z"
            transform="translate(1012 258)"
          />
        </g>
      </g>
    </g>
  </svg>
)
