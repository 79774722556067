import React, { SVGProps } from 'react'
export const BlogNormalSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={18} height={20} x={1} y={2} fill="url(#0268bdcf)" rx={4} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.888 13.61c0 .216.174.39.39.39h2.332a.39.39 0 0 0 .39-.39V8.695c0-3.007-3.112-3.198-5.559-2.03-1.621-1.032-3.588-.626-5.515.055a.33.33 0 0 0-.181.14s-.04.103-.04.185v6.568c0 .215.175.387.39.387h2.333a.39.39 0 0 0 .39-.39V8.58c.607-.21.979-.057.979.364v4.666c0 .216.174.39.389.39h2.333a.39.39 0 0 0 .39-.39V8.58c.607-.21.979-.057.979.364v4.666Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#8f94c292)"
      fillRule="evenodd"
      d="M23.788 8.163a.3.3 0 0 1 0 .424l-7.188 7.2a.3.3 0 0 1-.119.072l-2.915.955a.3.3 0 0 1-.378-.38l.968-2.904a.3.3 0 0 1 .072-.117l7.19-7.2a.3.3 0 0 1 .424 0l1.947 1.95Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#87cae76f)"
      fillRule="evenodd"
      d="M21.788 10.163a.3.3 0 0 1 0 .424l-7.188 7.2a.3.3 0 0 1-.119.072l-2.915.955a.3.3 0 0 1-.378-.38l.968-2.904a.3.3 0 0 1 .072-.117l7.19-7.2a.3.3 0 0 1 .424 0l1.947 1.95Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="0268bdcf"
        x1={10}
        x2={10}
        y1={2}
        y2={22}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BC5FE" />
        <stop offset={1} stopColor="#49ACEB" />
      </linearGradient>
      <linearGradient
        id="8f94c292"
        x1={20}
        x2={20}
        y1={17}
        y2={4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EDAFF" />
        <stop offset={0.601} stopColor="#60BDF8" />
      </linearGradient>
      <linearGradient
        id="87cae76f"
        x1={18}
        x2={18}
        y1={19}
        y2={6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CFEDFF" />
        <stop offset={1} stopColor="#85D0FF" />
      </linearGradient>
    </defs>
  </svg>
)
