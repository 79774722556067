import React from 'react'
import { Colors } from 'common-constants/colors'

export const SearchSvg = ({ stroke: color = Colors.link }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke={color}>
      <path strokeWidth="2" d="M17 16l5.5 5.5" />
      <path
        strokeWidth="1.5"
        d="M19 10.5a7.501 7.501 0 0 1-15 0 7.5 7.5 0 0 1 15 0z"
      />
    </g>
  </svg>
)
