import React from 'react'

export const Search2ActiveSvg = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.75 14.9175L21 19.335"
      stroke="url(#paint0_linear-search-active)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="10.5"
      cy="10.5"
      r="6"
      fill="white"
      stroke="url(#paint1_linear-search-active)"
      strokeWidth="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear-search-active"
        x1="8.93368"
        y1="7.14738"
        x2="10.6762"
        y2="21.282"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2999E" />
        <stop offset="1" stopColor="#E06065" />
      </linearGradient>
      <linearGradient
        id="paint1_linear-search-active"
        x1="-16.4752"
        y1="-23.3841"
        x2="-9.48625"
        y2="24.3167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2999E" />
        <stop offset="1" stopColor="#E06065" />
      </linearGradient>
    </defs>
  </svg>
)
