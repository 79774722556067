import React, { SVGProps } from 'react'
export const BlogHoverSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="2" width="18" height="20" rx="4" fill="url(#1f97c3c3)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8878 13.6105C12.8878 13.8256 13.0622 14 13.2773 14H15.6105C15.8256 14 15.9999 13.8256 15.9999 13.6105V8.69506C15.9999 5.68839 12.8878 5.49696 10.441 6.66501C8.81996 5.6326 6.85314 6.03873 4.9261 6.71954C4.85261 6.7455 4.78358 6.79191 4.74504 6.85985C4.74504 6.85985 4.70581 6.96282 4.70581 7.04495C4.70581 8.85866 4.70581 12.4345 4.70581 13.6131C4.70581 13.8282 4.88017 14 5.09526 14H7.42845C7.64354 14 7.8179 13.8256 7.8179 13.6105V8.57987C8.42496 8.37007 8.79654 8.52255 8.79654 8.94381V13.6105C8.79654 13.8256 8.9709 14 9.18599 14H11.5192C11.7343 14 11.9086 13.8256 11.9086 13.6105V8.57987C12.5157 8.37007 12.8878 8.52255 12.8878 8.94381V13.6105Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7885 8.1629C23.9054 8.28002 23.9053 8.46968 23.7884 8.58678L16.6003 15.7862C16.567 15.8196 16.5262 15.8446 16.4814 15.8593L13.5665 16.8144C13.3322 16.8911 13.1105 16.6683 13.1884 16.4345L14.1561 13.5302C14.1708 13.4861 14.1956 13.446 14.2284 13.4131L21.4175 6.21268C21.5347 6.09528 21.725 6.0953 21.8422 6.21272L23.7885 8.1629Z"
      fill="url(#917bd893)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7885 10.1629C21.9054 10.28 21.9053 10.4697 21.7884 10.5868L14.6003 17.7862C14.567 17.8196 14.5262 17.8446 14.4814 17.8593L11.5665 18.8144C11.3322 18.8911 11.1105 18.6683 11.1884 18.4345L12.1561 15.5302C12.1708 15.4861 12.1956 15.446 12.2284 15.4131L19.4175 8.21268C19.5347 8.09528 19.725 8.0953 19.8422 8.21272L21.7885 10.1629Z"
      fill="url(#efb110dd)"
    />
    <defs>
      <linearGradient
        id="1f97c3c3"
        x1="10"
        y1="2"
        x2="10"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4CA4DC" />
        <stop offset="1" stopColor="#0E7FC5" />
      </linearGradient>
      <linearGradient
        id="917bd893"
        x1="20"
        y1="17"
        x2="20"
        y2="4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9EDAFF" />
        <stop offset="0.600962" stopColor="#2FA5F0" />
      </linearGradient>
      <linearGradient
        id="efb110dd"
        x1="18"
        y1="19"
        x2="18"
        y2="6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#67C2FC" />
        <stop offset="1" stopColor="#6BC6FF" />
      </linearGradient>
    </defs>
  </svg>
)
