import React, { FC } from 'react'

export const ActivityActiveSvg: FC<{ size?: number }> = ({ size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient
        id="p895j7dvya"
        x1="50%"
        x2="50%"
        y1="-20.703%"
        y2="140.976%"
      >
        <stop offset="0%" stopColor="#FF9C70" />
        <stop offset="100%" stopColor="#F56323" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#p895j7dvya)" transform="translate(-808 -347)">
        <g>
          <path
            d="M9.597 1h5.616c.52 0 .94.421.94.94 0 .13-.026.258-.078.377l-2.047 4.686c-.208.476.01 1.03.485 1.238.12.052.247.079.377.079h3.099c.52 0 .94.421.94.94 0 .221-.077.434-.218.604l-10 11.972c-.333.398-.926.452-1.325.119-.277-.232-.397-.602-.309-.952l1.754-6.95c.127-.504-.178-1.015-.681-1.142-.076-.02-.153-.029-.23-.029H6.221c-.52 0-.94-.421-.94-.94 0-.082.01-.163.031-.242l2.465-9.3c.219-.826.965-1.4 1.819-1.4z"
            transform="translate(808 347)"
          />
        </g>
      </g>
    </g>
  </svg>
)
